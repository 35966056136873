@import './src/scss/variables';
@import './src/scss/app';

.my-flex-cont {
  display: flex;
  justify-content: center;
  align-content: center;
}

.my-flex-box {
  margin: 5px;
  padding: 5px;
  flex: 0 1 200px;
  align-self: stretch;
  flex-direction: row;
  
  &.operation {
    flex: 0 1 auto;
    align-self: center;
  }
}

$max_matrix_row: 9;
$max_matrix_col: 9;

.matrix {
  padding: 1px;
  background-color: #b1b6bf;
  display: inline-grid;
  align-items: center;
  row-gap: 1px;
  justify-items: center;
  @for $x from 1 through $max_matrix_row {
    &.size-x-#{$x} {
      grid-template-rows: repeat($x, 40px);
    }
  }
}

.matrix-row {
  display: grid;
  column-gap: 1px;
  @for $y from 1 through $max_matrix_col {
    &.size-y-#{$y} {
      grid-template-columns: repeat($y, 40px);
    }
  }
}

.matrix-cell {
  display: block;
  input {
    background-color: white;
    display:inline;
    width: 40px;
    line-height: 40px;
    text-align: center;
  }
}

.set-size {
  > * {
    display: inline;
    > li {
      display: inline;
    }
  }
}